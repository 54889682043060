(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:Order
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('Order', Order);

  function Order(wbResource) {
    return wbResource('order', 'orders', {}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      saveExitData: {
        method: 'POST',
        url: 'saveExitData'
      },
      vip: {
        method: 'POST',
        url: 'users/:id/companies/:companyId/orders/vip',
        params: {
          id: '@id',
          companyId: '@companyId'
        }
      },
      reorder: {
        method: 'POST',
        url: 'users/:id/companies/:companyId/reorder',
        params: {id: '@id', companyId: '@companyId'}
      }
    });
  }
}());
